import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticleCardComponent } from './components/article-card/article-card.component';
import { RouterModule } from '@angular/router';
import { SharedUiModule } from '@graat-hp/shared/ui';

@NgModule({
  imports: [CommonModule, SharedUiModule, RouterModule],
  declarations: [ArticleCardComponent],
  exports: [ArticleCardComponent],
})
export class BlogUiModule {}
