<section class="fistview main-section__width">
  <h1>ITとチームの力を引き出し<br />エンタープライズDXを推進する</h1>
  <article font-h6 class="description">
    Graatは企業が持っている「IT」と「チーム」の力を最大限に引き出し「プロセスとテクノロジーの変革」を通じて、エンタープライズ領域のデジタルトランスフォーメーション（DX）を支援します。
  </article>
  <a graat-hp-button color="graat" routerLink="advantage">Graatの強み</a>
</section>
<section class="news">
  <graat-hp-news class="main-section__width"></graat-hp-news>
</section>
<section class="service main-section__width">
  <div
    class="what-we-do"
    inViewport
    [inViewportOptions]="{ threshold: [1] }"
    (inViewportAction)="scrollInToYoutube($event)"
  >
    <h2>WHAT WE DO</h2>
    <div class="iframe-warper">
      <iframe
        id="what-we-do-graat"
        width="560"
        height="315"
        src="https://www.youtube.com/embed/F_YMBtoQ0nk?controls=1&loop=1&playlist=F_YMBtoQ0nk&enablejsapi=1&autoplay=0&cc_load_policy=1"
        title="Graatとは - エンタープライズDX推進を実現する4つの領域"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  </div>
  <graat-hp-service-menu></graat-hp-service-menu>
  <footer>
    <a graat-hp-button color="graat" routerLink="service">サービス</a>
  </footer>
  <section class="atlassian">
    <img
      loading="lazy"
      src="https://cdn.graat.co.jp/assets/logos/atlassian.png"
      alt="atlassian logo"
    />
    <span
      >当社のソフトウェア設計開発で活用しているアトラシアン製品を紹介します。</span
    >
    <a graat-hp-button color="graat" href="https://atlassian.gxp.co.jp"
      >VIEW MORE</a
    >
  </section>
</section>
<section class="case-study">
  <header>CASE STUDY</header>
  <div class="cases" graat-hp-grid gap-x4 scroll-horizontally-w300>
    <graat-hp-cs-ui-card
      [src]="caseStudy.ntteast.cover.base64"
      [alt]="caseStudy.ntteast.cover.alt"
      [headline]="caseStudy.ntteast.companyName"
      [body]="caseStudy.ntteast.description"
      [routerLink]="caseStudy.ntteast.path"
    ></graat-hp-cs-ui-card>
    <graat-hp-cs-ui-card
      [src]="caseStudy.imssol.cover.base64"
      [alt]="caseStudy.imssol.cover.alt"
      [headline]="caseStudy.imssol.companyName"
      [body]="caseStudy.imssol.description"
      [routerLink]="caseStudy.imssol.path"
    ></graat-hp-cs-ui-card>
    <graat-hp-cs-ui-card
      [src]="caseStudy.kwc.cover.base64"
      [alt]="caseStudy.kwc.cover.alt"
      [headline]="caseStudy.kwc.companyName"
      [body]="caseStudy.kwc.description"
      [routerLink]="caseStudy.kwc.path"
    ></graat-hp-cs-ui-card>
  </div>
  <footer>
    <a graat-hp-button color="graat" routerLink="case-study">事例紹介</a>
  </footer>
</section>
<section class="advantage">
  <graat-hp-business-ecosystem></graat-hp-business-ecosystem>
  <div class="illustration"></div>
  <div class="description">
    <h2>Graatの強み</h2>
    <article font-h6>
      <br />
      テクノロジーとプロセスから、エンタープライズDXを実現する。
    </article>
    <footer>
      <a graat-hp-button color="graat" routerLink="advantage">VIEW MORE</a>
    </footer>
  </div>
</section>
<section class="blog">
  <header>
    <h3>BLOG&nbsp;/&nbsp;NEWS</h3>
  </header>
  <graat-hp-latest-article></graat-hp-latest-article>
  <footer>
    <a graat-hp-button color="graat" routerLink="blogs">ブログ・お知らせ</a>
  </footer>
</section>
