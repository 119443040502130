import { Component } from '@angular/core';
import {
  ArticleData,
  UserEventHookTransferStateService,
} from '@graat-hp/shared/util';
import { Apollo, gql } from 'apollo-angular';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';

@Component({
  selector: 'graat-hp-latest-article',
  templateUrl: './latest-article.component.html',
  styleUrls: [
    './latest-article.component.scss',
    '../../../scss/home-grid-overlay.scss',
  ],
})
export class LatestArticleComponent {
  public readonly articleQueryRef$ = this.apollo.watchQuery<{
    articles: ArticleData;
  }>({
    query: gql`
      query LatestArticle($today: DateTime) {
        articles(
          first: 3
          orderBy: publicationDate_DESC
          ${environment.production ? 'stage: PUBLISHED' : ''}
          where: { publicationDate_lt: $today }
        ) {
          id
          title
          summary
          writerJobTitle
          publicationDate
          thumbnail {
            url
          }
          articleTags {
            tags
          }
          writer {
            name
            photo {
              url
            }
          }
        }
      }
    `,
    variables: {
      today: new Date().toISOString(),
    },
  });

  latests$ = this.transferState.useScullyTransferState(
    'article-latests',
    this.articleQueryRef$.valueChanges.pipe(
      map((result) => result.data.articles)
    )
  );

  constructor(
    private apollo: Apollo,
    private transferState: UserEventHookTransferStateService
  ) {}
}
