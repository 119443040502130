<div class="menu">SERVICE MENU</div>
<div class="links main-section__width">
  <div>
    <header>組織アジャイル化</header>
    <a
      graat-hp-button-next
      routerLink="/service/team-process"
      routerLinkActive="active"
      >チームプロセス支援</a
    >
    <a
      graat-hp-button-next
      routerLink="/service/organizational-process"
      routerLinkActive="active"
      >組織プロセス支援</a
    >
  </div>
  <div>
    <header>サービスデザイン</header>
    <a
      graat-hp-button-next
      routerLink="/service/value-definition"
      routerLinkActive="active"
      >価値定義</a
    >
    <a
      graat-hp-button-next
      routerLink="/service/digital-service-design"
      routerLinkActive="active"
      >デジタルサービスデザイン(UI/UX)</a
    >
  </div>
  <div>
    <header>DevOps</header>
    <a
      graat-hp-button-next
      routerLink="/service/dev-ops"
      routerLinkActive="active"
      >DevOps導入支援</a
    >
    <a
      graat-hp-button-next
      routerLink="/service/atlassian-support"
      routerLinkActive="active"
      >アトラシアン製品導入支援</a
    >
  </div>
  <div>
    <header>マイクロサービス化</header>
    <a
      graat-hp-button-next
      routerLink="/service/microservice"
      routerLinkActive="active"
      >マイクロサービス化支援</a
    >
    <a
      graat-hp-button-next
      routerLink="/service/cloud-shift"
      routerLinkActive="active"
      >クラウドシフト推進</a
    >
  </div>
</div>
