<section class="header main-section__width">
  <div>
    <h1>Graatの強み</h1>
    <article font-h6>
      テクノロジーとプロセスから、エンタープライズDXを実現する。
    </article>
  </div>
</section>
<section class="messages main-section__width">
  <ol>
    <li advantage-card>
      <h4>
        我々は、テクノロジーとプロセスの両面から働きかけるエンタープライズDX支援チームです。
      </h4>
      <article>
        Graatは、エンタープライズDXの領域において、テクノロジーとプロセスの両面から支援することが可能なハイブリッドDX支援チームです。当社は、クラウド技術やマイクロサービスといった技術要素と、スクラムやアジャイル組織などのプロセス要素の両面について深い知見があります。また、個別チームやデジタルサービスの立ち上げだけではなく、企業全体での基盤となるようなレベルまで対応が可能です。
      </article>
      <footer>
        <a graat-hp-button-next color="graat" routerLink="/service"
          >我々のサービスについて</a
        >
      </footer>
    </li>
    <li advantage-card>
      <h4>
        業界トップクラスの企業への支援を通じて得た、高い実現力があります。
      </h4>
      <article>
        Graatは、クラウド技術やアーキテクチャ設計、あるいは開発プロセスや組織論といった理論についての深い知見があるだけではありません。各業界のトップクラスのみなさまへの支援を通じて、それらの理論を日本の大企業に提要にするにあたって、どんな障害があり、どう解決していくのかについて経験を積んできました。この経験によって、エンタープライズDXを実現可能にしています。
      </article>
      <footer>
        <a graat-hp-button-next color="graat" routerLink="/case-study"
          >我々の事例・実績について</a
        >
      </footer>
    </li>
    <li advantage-card>
      <h4>何よりも重要なのは「チームワーク」と「人材」です</h4>
      <article>
        Graatの最大の特徴はメンバーと、そのメンバーのチームワークです。個性豊かなメンバーが、それぞれの力を発揮しつつ、チームとして機能することで最高のパフォーマンスをお届けしています。
      </article>
      <footer>
        <a graat-hp-button-next color="graat" routerLink="/blogs"
          >メンバーが執筆するブログを見る</a
        >
        <a graat-hp-button-next color="graat" routerLink="/recruit"
          >チームにジョインする</a
        >
      </footer>
    </li>
  </ol>
</section>
