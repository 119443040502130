import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[graatHpScrollIntoView]',
})
export class ScrollIntoViewDirective {
  @Input('graatHpScrollIntoView') element!: HTMLElement;

  @HostListener('click', ['$event']) onClick($event: Event) {
    if (this.element) {
      this.element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }
}
