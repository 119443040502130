export const scrollIntoFragment = (fragment: string) => {
  setTimeout(() => {
    const elements = document.querySelectorAll(`#${fragment}`);
    if (elements.length) {
      elements[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
      elements.forEach((elem) => {
        elem.classList.add('target');
      });
    }
  }, 100);
};
