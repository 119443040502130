import { ScriptLoaderService } from './script-loader.service';

export class ScriptLoaderServiceMock {
  public text = () => void 0;
  public script = () => Promise.resolve();
  public stylesheet = () => Promise.resolve();
}

/**
 * @example
 * { provide: ScriptLoaderService, useFactory: ScriptLoaderServiceMockFactory }
 */
export function ScriptLoaderServiceMockFactory() {
  return new ScriptLoaderServiceMock();
}

export const scriptLoaderServiceMockProvider = {
  provide: ScriptLoaderService,
  useFactory: ScriptLoaderServiceMockFactory,
};
