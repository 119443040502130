import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CaseStudyMetadataService,
  ScriptLoaderService,
} from '@graat-hp/shared/util';
import { InViewportMetadata } from 'ng-in-viewport';
import { BehaviorSubject } from 'rxjs';
import { filter, scan } from 'rxjs/operators';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, no-var
declare var YT: any;

interface InViewportEvent {
  [InViewportMetadata]: {
    entry: IntersectionObserverEntry;
  };
  target: HTMLElement;
  visible: boolean;
}

@Component({
  selector: 'graat-hp-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss', '../../../scss/home-grid-overlay.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  public autoPlaySubject = new BehaviorSubject<{
    videoFrame?: {
      mute: () => void;
      playVideo: () => void;
      pauseVideo: () => void;
    };
    isIntersecting?: boolean;
  }>({
    isIntersecting: false,
  });

  constructor(
    private script: ScriptLoaderService,
    public caseStudy: CaseStudyMetadataService
  ) {
    this.autoPlaySubject
      .asObservable()
      .pipe(
        scan(({ videoFrame, isIntersecting }, c) => ({
          videoFrame: c.videoFrame ?? videoFrame,
          isIntersecting: c.isIntersecting ?? isIntersecting,
        })),
        filter(({ videoFrame }) => Boolean(videoFrame))
      )
      .subscribe(({ videoFrame, isIntersecting }) => {
        if (isIntersecting) {
          videoFrame.mute();
          videoFrame.playVideo();
        } else {
          videoFrame.pauseVideo();
        }
      });
  }

  ngOnInit(): void {
    const video = document.querySelector<HTMLVideoElement>('video.background');
    if (video) {
      video.classList.add('visible');
    }

    this.script
      .script('https://www.youtube.com/iframe_api', { async: true })
      .then(() => {
        YT.ready(() => {
          new YT.Player('what-we-do-graat', {
            events: {
              onReady: (event) => {
                this.autoPlaySubject.next({ videoFrame: event.target });
              },
            },
          });
        });
      });
  }

  ngOnDestroy(): void {
    const video = document.querySelector<HTMLVideoElement>('video.background');
    if (video) {
      video.classList.remove('visible');
    }
  }

  scrollInToYoutube(event: InViewportEvent): void {
    const {
      [InViewportMetadata]: {
        entry: { isIntersecting },
      },
    } = event;
    this.autoPlaySubject.next({ isIntersecting });
  }
}
