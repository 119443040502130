import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './components/card/card.component';
import { BusinessEcosystemComponent } from './components/business-ecosystem/business-ecosystem.component';
import { UrlTranslatePipe } from './pipes/url-translate.pipe';
import { ScrollIntoViewDirective } from './directives/scroll-into-view.directive';
import { DateFormatDistancePipe } from './pipes/date-format-distance.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    CardComponent,
    BusinessEcosystemComponent,
    UrlTranslatePipe,
    ScrollIntoViewDirective,
    DateFormatDistancePipe,
  ],
  exports: [
    CardComponent,
    BusinessEcosystemComponent,
    UrlTranslatePipe,
    ScrollIntoViewDirective,
    DateFormatDistancePipe,
  ],
})
export class SharedUiModule {}
