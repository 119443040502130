import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

/**
 * @see https://developer.mozilla.org/ja/docs/Web/HTML/Element/script#attributes
 */
export interface ScriptLoadOptions {
  async?: boolean;
  defer?: boolean;
  crossorigin?: boolean;
}

export const duplicateEvent = new Event('duplicateLoad');

const initScriptOption: ScriptLoadOptions = {
  async: false,
  defer: false,
  crossorigin: false,
};

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  /**
   * map to add a resource to a singleton
   */
  private readonly resouceMap = new Map<string, boolean>();

  constructor(@Inject(DOCUMENT) private document: Document) {}

  text(code: string, options: ScriptLoadOptions = {}): void {
    if (this.resouceMap.has(code)) {
      return void 0;
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.textContent = code;
    this.document.head.appendChild(this.setScriptOption(script, options));
    this.resouceMap.set(code, true);
  }

  public async script(
    url: string,
    options: ScriptLoadOptions = {}
  ): Promise<Event> {
    return new Promise((loaded, setError) => {
      if (this.resouceMap.has(url)) {
        return loaded(duplicateEvent);
      }
      const script = this.document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.onload = loaded;
      script.onerror = setError;
      this.document.head.appendChild(this.setScriptOption(script, options));
      this.resouceMap.set(url, true);
    });
  }

  public async scriptWithId(
    id: string,
    url: string,
    options: ScriptLoadOptions = {}
  ): Promise<Event> {
    return new Promise((loaded, setError) => {
      if (this.resouceMap.has(url)) {
        return loaded(duplicateEvent);
      }
      const script = this.document.createElement('script');
      script.type = 'text/javascript';
      script.id = id;
      script.src = url;
      script.onload = loaded;
      script.onerror = setError;
      this.document.head.appendChild(this.setScriptOption(script, options));
      this.resouceMap.set(url, true);
    });
  }

  public async stylesheet(url: string): Promise<Event> {
    if (this.resouceMap.has(url)) {
      return Promise.resolve(duplicateEvent);
    }
    return new Promise((loaded, setError) => {
      const link = this.document.createElement('link');
      link.href = url;
      link.rel = 'stylesheet';
      link.onload = loaded;
      link.onerror = setError;
      this.document.head.appendChild(link);
      this.resouceMap.set(url, true);
    });
  }

  public link(href: string, rel: 'prefetch' = 'prefetch'): void {
    if (this.resouceMap.has(href)) {
      return void 0;
    }
    const link = document.createElement('link');
    link.rel = rel;
    link.href = href;
    this.document.head.appendChild(link);
    this.resouceMap.set(href, true);
  }

  private setScriptOption(
    script: HTMLScriptElement,
    _options: ScriptLoadOptions
  ): HTMLScriptElement {
    const options = {
      ...initScriptOption,
      ..._options,
    };

    if (options.async) {
      script.setAttribute('async', '');
    }

    if (options.defer) {
      script.setAttribute('defer', '');
    }

    return script;
  }
}
