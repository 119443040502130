<section class="main-section__width">
  <h1>プライバシーポリシー</h1>
  <h3>個人情報保護方針</h3>
  <p>
    当社における個人情報保護の活動は、当社の事業活動にとって非常に重要なテーマであるとともに顧客及び取扱う個人情報のすべての情報主体の方にとっても非常に重要であると考えます。<br />
    したがって、当社は個人情報保護活動のための全社的な取り組みを実施し、顧客及び取扱う個人情報のすべての方へ安心を提供し、社会的責任の責務を果たすことを確実にするために以下の方針を定めこれを遵守いたします。
  </p>
  <ol>
    <li>
      <h5>個人情報の収集について</h5>
      当社が個人情報の収集を行う場合は、<br />
      （1）収集目的の達成のために必要な範囲のみ収集し、目的外利用は行ないません。<br />
      （2）適法且つ公正な手段を用い行います。<br />
      （3）事前に収集目的を明らかにし、同意の上で行います。<br />
      また、当社が収集した個人情報は、適切に管理し、その利用、提供は同意を得た範囲に限定し、それ以外の第３者への開示、提供は行いません。
    </li>
    <li>
      <h5>法令遵守について</h5>
      当社は、個人情報に関連する法令及びその他の規範を遵守します。また、当社の管理の仕組みに、これらの法令及びその他の規範を常に適合させます。
    </li>
    <li>
      <h5>個人情報の適正管理</h5>
      当社は、個人情報の正確性及び安全性を確保するために、セキュリティ対策をはじめとする安全対策を実施し、個人情報への不正アクセス、個人情報の紛失、破壊、改ざん、漏洩などを確実に予防し、問題が生じた場合は適切に是正します。
    </li>
    <li>
      <h5>苦情及び相談への対応について</h5>
      本人から個人情報の訂正、追加または削除の申出を受けたときは、遅滞なく適切な処理を行います。且つ、個人情報の保護と適正な取扱いに関する苦情に適切かつ迅速に対応します。
    </li>
    <li>
      <h5>継続的改善について</h5>
      内部監査及びマネジメントレビューの機会や苦情及び相談の機会を通じて、個人情報保護活動を継続的に改善し、常に最良の状態を維持します。
    </li>
  </ol>
  <p class="txta_r">
    平成30年 11月 1日<br />
    グロース・アーキテクチャ＆チームス株式会社<br />
    代表取締役社長　 鈴木　雄介<br />
    令和元年 10月7日改定
  </p>
  <h3>個人情報保護への取り組みについて</h3>
  <ol>
    <li>
      <h5>個人情報について</h5>
      個人に関する情報であって、氏名・生年月日・個人別に付けられた番号、その他の記述等によって特定の個人を識別できるもの（他の情報と照合することで容易に特定の個人を識別できるものも含む）をいいます。
    </li>
    <li>
      <h5>個人情報の利用目的について</h5>
      当社は、下記の目的で個人情報を利用いたします。 <br />
      <ul>
        <li>
          当社の全ての従業者（雇用形態を問わず）に関する人事・労務・給与の管理
        </li>
        <li>
          当社製品を購入頂いた顧客に対する修理及びその他保守業務に関する連絡
        </li>
        <li>当社就業希望者の採用に関する業務の管理</li>
        <li>当社業務のサービス向上、維持および障害に関する情報の連絡</li>
        <li>当社業務に関する営業活動における連絡</li>
      </ul>
    </li>
    <li>
      <h5>個人情報の目的外使用の禁止</h5>
      取得させていただいた個人情報は、本人の承諾がない限り目的外の使用はいたしません。ただし、本人の承諾がある場合や、個人の識別が不可能な状態にした後の統計データを研究資料等として利用する場合はこの限りではありません。
    </li>
    <li>
      <h5>個人情報の正確性と安全性の確保について</h5>
      個人情報の正確性を確保するため細心の注意を払いデータ保持に努めます。個人情報のデータ取扱いには細心の注意を払い紛失・破壊・漏えい・滅失・改ざんの防止、その他の安全管理のために必要かつ適切な措置を行ないます。
    </li>
    <li>
      <h5>個人情報の取扱いの委託</h5>
      当社の個人情報利用目的の範囲内において、個人情報の一部または全部の取扱いを外部に委託する場合があります。委託先の選定については個人情報の保護水準についての選定基準を設け厳重な審査を行ないます。選定後は、秘密保持契約を結び、委託先の管理・監督を行ないます。
    </li>
    <li>
      <h5>共同利用について</h5>
      営業仲介及びパートナー契約を締結した企業又は組織から収集した個人情報は、当該企業又は組織との間でのみ共同で利用します。
    </li>
    <li>
      <h5>第三者提供</h5>
      個人情報について、本人の承諾なしに、いかなる第三者に対しても開示・提供はいたしません。ただし、上記5.における取扱いの委託先は、第三者に含みません。
    </li>
    <li>
      <h5>個人情報の適法かつ公正な取得</h5>
      個人情報の取得は、適法かつ公正な手段によって行ないます。
    </li>
    <li>
      <h5>個人情報の開示・訂正等について</h5>
      当社の個人データに関し開示・訂正・追加・削除・利用停止および消去を希望される場合は、お申し出いただいた方が、本人であることを確認した上で合理的な期間および範囲で対応いたします。
    </li>
    <li>
      <h5>個人情報に関するお問い合わせ</h5>
      個人情報に関する質問・お問い合わせ等（上記8.の申し出も含む）がございましたら、下記連絡先までご連絡をくださいますようお願いいたします。
    </li>
  </ol>
  <h3>当サービスの使用に関する情報の使用について</h3>
  <ol>
    <li>
      当サービスではサービスの向上のため、サービスの使用状況の把握にGoogle
      LCC（以下「Google」）による「<a
        href="https://developers.google.com/analytics/?hl=ja"
        target="_blank"
        rel="noopener"
        >Google アナリティクス</a
      >」を使用し、情報の収集のためにクッキーを使用しています。クッキーは、サービスの使用者に関する情報をブラウザー内に保存する機能の総称です。<br />
    </li>
    <li>
      Google アナリティクスでデータが収集、処理される仕組みは、Googleによる「<a
        href="https://policies.google.com/technologies/partner-sites?hl=ja"
        target="_blank"
        rel="noopener"
        >Google のサービスを使用するサイトやアプリから収集した情報の Google
        による使用</a
      >」をご覧ください 。
    </li>
  </ol>
  <p class="txta_r">
    個人情報問合せ先：グロースエクスパートナーズ株式会社 管理本部<br />
    電話番号/03-5990-5423<br />
    受付時間/月～金(土・日・祝日・年末年始は除く)10：00～17：00
  </p>
</section>
