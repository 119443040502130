import { Injectable } from '@angular/core';
import {
  caseStudyBeng,
  caseStudyAthome,
  caseStudyFreedomland,
  caseStudyImdl,
  caseStudyLifenet,
  caseStudyTaisei,
  caseStudyAupay,
  caseStudyKwc,
  caseStudyImsSol,
  caseStudyNttEast,
} from './case-study';

export enum CaseStudys {
  ntteast = 'ntteast',
  imssol = 'imssol',
  kwc = 'kwc',
  // 掲載日を基にして、上3つは最新の事例で降順とし、以下は昇順とする
  // 事例を追加する場合は一番上に最新の事例を追加し、上記の最後の項目を一番下へ移動すること
  aupay = 'aupay',
  taisei = 'taisei',
  athome = 'athome',
  freedomland = 'freedomland',
  imdl = 'imdl',
  beng = 'beng',
  lifenet = 'lifenet',
}

@Injectable({
  providedIn: 'root',
})
export class CaseStudyMetadataService {
  [CaseStudys.athome] = caseStudyAthome;
  [CaseStudys.freedomland] = caseStudyFreedomland;
  [CaseStudys.imdl] = caseStudyImdl;
  [CaseStudys.beng] = caseStudyBeng;
  [CaseStudys.lifenet] = caseStudyLifenet;
  [CaseStudys.taisei] = caseStudyTaisei;
  [CaseStudys.aupay] = caseStudyAupay;
  [CaseStudys.kwc] = caseStudyKwc;
  [CaseStudys.imssol] = caseStudyImsSol;
  [CaseStudys.ntteast] = caseStudyNttEast;
}
