import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApolloModule } from 'apollo-angular';

import { ServiceUiModule } from '@graat-hp/service/api';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { BlogUiModule } from '@graat-hp/blog/api';

import { AppComponent } from './app.component';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { SharedUiModule } from '@graat-hp/shared/ui';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './components/router/not-found/not-found.component';
import { HomeComponent } from './components/router/home/home.component';
import { ToolbarComponent } from './components/dumb/toolbar/toolbar.component';
import { AdvantageComponent } from './components/router/advantage/advantage.component';
import {
  creeateLazyLoadRouteData,
  creeateRouteData,
  IS_PRODUCTION,
  MetadataService,
  UserEventHookTransferStateService,
} from '@graat-hp/shared/util';
import { FooterComponent } from './components/dumb/footer/footer.component';
import { GraphQLModule } from './graphql';
import { HttpClientModule } from '@angular/common/http';
import { CompanyComponent } from './components/router/company/company.component';
import { PrivacyComponent } from './components/router/privacy/privacy.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CaseStudyUiModule } from '@graat-hp/case-study/api';
import { NewsComponent } from './components/dumb/news/news.component';
import { LatestArticleComponent } from './components/dumb/latest-article/latest-article.component';
import { InViewportModule } from 'ng-in-viewport';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
    data: creeateRouteData({
      metadata: {
        title: 'Graat（グラーツ）-グロース・アーキテクチャ＆チームス株式会社',
        description:
          'Graatは企業が持っている「IT」と「チーム」の力を最大限に引き出し「プロセスとテクノロジーの変革」を通じて、エンタープライズ領域のDXを推進するコンサルティング会社です。',
      },
      breadcrumb: [],
    }),
  },
  {
    path: 'advantage',
    component: AdvantageComponent,
    data: creeateRouteData({
      metadata: {
        title: 'Graatの強み',
        description:
          'テクノロジーとプロセスから、エンタープライズDXを実現する。',
      },
      breadcrumb: [{ label: 'Graatの強み', routerLink: '/advantage' }],
    }),
  },
  {
    path: 'company',
    component: CompanyComponent,
    data: creeateRouteData({
      metadata: {
        title: '企業情報',
        description:
          'Graatの企業情報です。トップメッセージ、ビジョンとミッション、会社概要についてのご案内です。',
      },
      breadcrumb: [{ label: '企業情報', routerLink: '/company' }],
    }),
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
    data: creeateRouteData({
      metadata: {
        title: 'プライバシーポリシー',
        description: '',
      },
      breadcrumb: [{ label: 'プライバシーポリシー', routerLink: '/privacy' }],
    }),
  },
  {
    path: 'service',
    loadChildren: () =>
      import('@graat-hp/service/shell').then(
        (module) => module.ServiceShellModule
      ),
    data: creeateLazyLoadRouteData({
      breadcrumb: [{ label: 'サービス', routerLink: '/service' }],
    }),
  },
  {
    path: 'blogs',
    loadChildren: () =>
      import('@graat-hp/blog/shell').then((module) => module.BlogShellModule),
    data: creeateLazyLoadRouteData({
      breadcrumb: [{ label: 'ブログ・お知らせ', routerLink: '/blogs' }],
    }),
  },
  {
    path: 'recruit',
    loadChildren: () =>
      import('@graat-hp/recruit/shell').then(
        (module) => module.RecruitShellModule
      ),
    data: creeateLazyLoadRouteData({
      breadcrumb: [{ label: '採用', routerLink: '/recruit' }],
    }),
  },
  {
    path: 'case-study',
    loadChildren: () =>
      import('@graat-hp/case-study/shell').then(
        (module) => module.CaseStudyShellModule
      ),
    data: creeateLazyLoadRouteData({
      breadcrumb: [{ label: '事例紹介', routerLink: '/case-study' }],
    }),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('@graat-hp/contact/shell').then(
        (module) => module.ContactShellModule
      ),
    data: creeateLazyLoadRouteData({
      breadcrumb: [{ label: 'お問い合わせ', routerLink: '/contact' }],
    }),
  },
  {
    path: 'atlassian',
    loadChildren: () =>
      import('@graat-hp/atlassian/shell').then((module) => {
        if (location.assign) {
          location.assign('https://atlassian.gxp.co.jp');
        }
        return module.AtlassianShellModule;
      }),
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    HomeComponent,
    ToolbarComponent,
    AdvantageComponent,
    FooterComponent,
    CompanyComponent,
    PrivacyComponent,
    NewsComponent,
    LatestArticleComponent,
  ],
  imports: [
    InViewportModule,
    ApolloModule,
    BrowserModule,
    SharedUiModule,
    ServiceUiModule,
    OverlayModule,
    PortalModule,
    BlogUiModule,
    CaseStudyUiModule,
    BrowserAnimationsModule,
    ScullyLibModule.forRoot({
      useTransferState: true,
      alwaysMonitor: true,
    }),
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      // anchorScrolling: 'enabled',
    }),
    GraphQLModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.pwa,
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    MetadataService,
    UserEventHookTransferStateService,
    { provide: IS_PRODUCTION, useValue: environment.production },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private readonly meeta: MetadataService) {}
}
