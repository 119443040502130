<div graat-hp-grid scroll-horizontally-w300 gap-x4>
  <graat-hp-article-card
    *ngFor="let latest of latests$ | async"
    [pageNavigate]="['/blogs', latest.id]"
    [cover]="latest?.thumbnail?.url"
    [alt]="latest.title"
    [title]="latest.title"
    [publicationDate]="latest.publicationDate"
    [description]="latest.summary"
    [jobTitle]="latest.jobTitle"
    [writer]="latest.writer"
    [tags]="latest.articleTags"
  ></graat-hp-article-card>
</div>
