import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedUiModule } from '@graat-hp/shared/ui';
import { RouterModule } from '@angular/router';

import { CsUiCardComponent } from './components/cs-ui-card/cs-ui-card.component';
import { CaseStudyEnableFilterPipe } from './pipe/case-study-enable-filter.pipe';

@NgModule({
  imports: [CommonModule, SharedUiModule, RouterModule],
  declarations: [CsUiCardComponent, CaseStudyEnableFilterPipe],
  exports: [CsUiCardComponent, CaseStudyEnableFilterPipe],
})
export class CaseStudyUiModule {}
