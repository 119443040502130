import { Component, Input } from '@angular/core';
import { ArticleWriter } from '@graat-hp/shared/util';

@Component({
  selector: 'graat-hp-article-card',
  templateUrl: './article-card.component.html',
  styleUrls: ['./article-card.component.scss'],
})
export class ArticleCardComponent {
  private _src =
    'https://cdn.graat.co.jp/assets/graat_vertical_padding630x1200.png';
  @Input()
  public set cover(value: string | void) {
    this._src = value || this._src;
  }
  public get cover() {
    return this._src;
  }
  @Input()
  public alt = '';
  @Input()
  public title = '';
  @Input()
  public description = '';
  @Input()
  public publicationDate = new Date().toISOString();
  @Input()
  public writer!: ArticleWriter;
  @Input()
  public jobTitle = '';
  @Input()
  public tags: { tags: string }[] = [];
  @Input()
  pageNavigate: string[] = [];
}
